const details = [
  {
    id: 1,
    name: 'Quiz',
    image: 'images/quiz.png',
    link: 'https://pythonquizclasses-production.up.railway.app/',
    language: 'Python',
  },
  {
    id: 2,
    name: 'Blackjack',
    image: 'images/blackjack.png',
    link: 'https://pythonblackjack-production.up.railway.app/',
    language: 'Python',
  },
  {
    id: 3,
    name: 'Hangman',
    image: 'images/hangman.png',
    link: 'https://victoria.pythonanywhere.com/',
    language: 'Python',
  },
  {
    id: 4,
    name: 'My Blog - Bits and Scribbles',
    image: 'images/site.jpg',
    link: 'https://www.bitsandscribbles.com/',
    language: 'Node js, Express, MongoDb, EJS',
  },
  // {
  //   id: 5,
  //   name: 'Disappearing Notifications',
  //   image: 'images/toastMessages.png',
  //   link: 'https://disappearingnotifications.netlify.app/',
  //   language: 'JavaScript, CSS, HTML',
  // },
  // {
  //   id: 6,
  //   name: 'Vertical Slider',
  //   image: 'images/verticalSlider.png',
  //   link: 'https://simpleverticalslider.netlify.app/',
  //   language: 'JavaScript, CSS, HTML',
  // },
  {
    id: 7,
    name: 'Greenish World',
    image: 'images/greenish.png',
    link: 'https://greenishworld.netlify.app/',
    language: 'JavaScript, Phaser',
  },
  // {
  //   id: 8,
  //   name: 'Simple Drawing Pad',
  //   image: 'images/drawing.png',
  //   link: 'https://simpledrawingpad.netlify.app/',
  //   language: 'JavaScript, CSS, HTML',
  // },
  {
    id: 9,
    name: 'Drink Water🥛🥛🥛',
    image: 'images/water.png',
    link: 'https://howmuchwater.netlify.app/',
    language: 'JavaScript, CSS, HTML',
  },
  {
    id: 10,
    name: 'Random Choice Picker',
    image: 'images/choices.png',
    link: 'https://randomchoices.netlify.app/',
    language: 'JavaScript, CSS, HTML',
  },
  {
    id: 11,
    name: 'FAQ Cards Toggle',
    image: 'images/faq.png',
    link: 'https://servicenow-faqs.netlify.app/',
    language: 'JavaScript, CSS, HTML',
  },
  {
    id: 12,
    name: 'Event Key Codes',
    image: 'images/keycodes.png',
    link: 'https://eventkeycodesok.netlify.app/',
    language: 'JavaScript, CSS, HTML',
  },
  {
    id: 13,
    name: '😆 Dad Jokes',
    image: 'images/joke.png',
    link: 'https://sillydadjokes.netlify.app/',
    language: 'JavaScript, CSS, HTML',
  },
  {
    id: 14,
    name: '🍎 Apple Catcher 🧺',
    image: 'images/apple_catcher.png',
    link: 'https://superlative-shortbread-44dd7e.netlify.app/',
    language: 'JavaScript, Phaser',
  },
  {
    id: 15,
    name: '🃏 Flash Cards (API) 🤔',
    image: 'images/flash.png',
    link: 'https://bejewelled-unicorn-e13d98.netlify.app/',
    language: 'JavaScript, CSS, React, API',
  },
  // {
  //   id: 16,
  //   name: '👀 Minion 👀',
  //   image: 'images/minion.png',
  //   link: 'https://steady-tiramisu-8d8866.netlify.app/',
  //   language: 'JavaScript, CSS',
  // },
  {
    id: 17,
    name: '🔮 Magic Ball 8🔮',
    image: 'images/magic.png',
    link: 'https://timely-fox-b010dc.netlify.app/',
    language: 'DOM, JavaScript, CSS',
  },
  {
    id: 18,
    name: '🍪 Pac-Man 👻',
    image: 'images/pacman.png',
    link: 'https://timely-cranachan-0d6ab6.netlify.app/',
    language: 'DOM, JavaScript, CSS',
  },
  {
    id: 19,
    name: '🐍 Snake Game',
    image: 'images/snake.png',
    link: 'https://fluffy-pudding-0f0eac.netlify.app/',
    language: 'DOM, JavaScript, CSS',
  },
  {
    id: 20,
    name: '🔠 Guess the Word!',
    image: 'images/guesstheword.png',
    link: 'https://tourmaline-cajeta-b0d8d8.netlify.app/',
    language: 'DOM, JavaScript',
  },
  {
    id: 21,
    name: '🗺️ Map your workouts',
    image: 'images/map.png',
    link: 'https://effervescent-travesseiro-b371e9.netlify.app/',
    language:
      'ObjectOrientedProgramming, JavaScript, Leaflet, API, Geolocation',
  },
  {
    id: 22,
    name: '💰 Fictive Bank Site',
    image: 'images/banking.jpg',
    link: 'https://stirring-kashata-29068c.netlify.app/',
    language:
      'Advanced DOM manipulation, Slide element, Sticky Navigation, DOM events, IntersectionObserver, JavaScript, CSS',
  },
  {
    id: 23,
    name: '🕹️ Games board',
    image: 'images/games_board.PNG',
    link: 'https://mygamesboard.netlify.app/',
    language: 'DOM manipulation, JavaScript, CSS',
  },
  {
    id: 24,
    name: '❌ Tic-Tac-Toe',
    image: 'images/tictactoe.png',
    link: 'https://snazzy-speculoos-db3893.netlify.app/',
    language: 'DOM manipulation, JavaScript, CSS',
  },
  {
    id: 25,
    name: '💵 Fictive Virtual Personal Banking',
    image: 'images/fictive_bank.png',
    link: 'https://clinquant-cajeta-42eed6.netlify.app/',
    language: 'DOM manipulation, JavaScript, CSS',
  },
  {
    id: 26,
    name: '🤯 Memory Game',
    image: 'images/memory1.png',
    link: 'https://vermillion-tapioca-55e5ed.netlify.app/',
    language: 'DOM manipulation, JavaScript, CSS',
  },
  {
    id: 27,
    name: '🧩 Slide Puzzle',
    image: 'images/sponge.png',
    link: 'https://extraordinary-peony-cd46ff.netlify.app/',
    language: 'DOM manipulation, JavaScript, CSS',
  },
  {
    id: 28,
    name: '🎲 Dice Game',
    image: 'images/pig-game.png',
    link: 'https://ornate-halva-c5686c.netlify.app/',
    language: 'DOM Class manipulation, JavaScript, CSS',
  },
  {
    id: 29,
    name: '🔢 Guess the number!',
    image: 'images/Guess_the_number.png',
    link: 'https://soft-pika-ed6b48.netlify.app/',
    language: 'DOM manipulation, JavaScript',
  },
  // {
  //   id: 30,
  //   name: 'Site Template',
  //   image: 'images/extraterestrial.png',
  //   link: 'https://vicmoon.github.io/CSS_SITE/',
  //   language: 'CSS, HTML, Responsive Template',
  // },
  {
    id: 31,
    name: 'JavaScript Game',
    image: 'images/game.png',
    link: 'https://vicmoon.github.io/Javascript_Mainproject/',
    language: 'CSS, HTML, JavaScript Game',
  },
  {
    id: 32,
    name: 'React Dictionary',
    image: 'images/Dictionary.JPG',
    link: 'https://inspiring-tanuki-a897c7.netlify.app/',
    language: 'CSS, API, HTML, JavaScript, React',
  },
  {
    id: 33,
    name: 'World Clock App',
    image: 'images/clock.JPG',
    link: 'https://phenomenal-unicorn-f160f9.netlify.app/',
    language: 'CSS, HTML, JavaScript, API, Responsive, moment.js',
  },
  {
    id: 34,
    name: 'Weather App in React',
    image: 'images/weather.JPG',
    link: 'https://illustrious-beignet-228d77.netlify.app/',
    language: 'React, CSS, HTML',
  },
  {
    id: 35,
    name: 'JavaScript Weather App',
    image: 'images/vanilajs.JPG',
    link: 'https://vanillaweathersearch.netlify.app/',
    language: 'JavaScript',
  },
];

export default details;
